/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Import styles shared between front end apps */
@import '../../../libs/frontend/shared/ui-assets/assets/styles/shared.scss';

// This element is in a popover,
// so view encapsulation prevents us from applying the style
// from component scss
.school-class-selection-container {
    display: flex;
    row-gap: 0.25rem;
    flex-direction: column;
    align-items: flex-start;

    label {
        display: inline-block;
        padding-inline: 0.75rem;
        padding-block: 0.5rem;
        margin-right: 0.5rem;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
    }

    input {
        appearance: none;
    }

    input[type='checkbox'] + label {
        background-color: var(--ion-color-light);
    }

    input[type='checkbox']:checked + label {
        color: white;
    }
}

// Dynamic modal height strategy from
// https://forum.ionicframework.com/t/how-can-i-set-modals-height-to-be-equal-to-its-content/214941/6
.planner-modal {
    --height: auto;
    --width: 90%;
    --max-width: 92ch;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            height: 80vh;
            overflow: auto;
        }
    }
}
.planner-category-modal {
    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            overflow: auto;
        }
    }
}

lib-planner-week-view,
lib-planner-print {
    .remove-first-last-lexical-margin {
        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}

@media print {
    // The name of this class has to match the one hardcoded
    // in detailed-planner.page.ts
    body.adjust-styles-during-printing {
        position: unset;
        height: unset;
        max-height: unset;
        transform: unset;
        overflow: unset;
        background-color: white;

        app-root {
            display: none;
        }
    }
}

/*
    Comment this style to test the print view
    in the DOM when working on it locally
*/
@media not print {
    lib-planner-print {
        display: none;
    }
}

.date-shift-confirmation-popover {
    --width: 350px;
}

/* Uncomment if you want to simulate safe areas to aid in css calculations/debugging */
//html {
//    --ion-safe-area-top: 1rem;
//    --ion-safe-area-right: 1rem;
//    --ion-safe-area-bottom: 2rem;
//    --ion-safe-area-left: 2rem;
//}
